<template>
  <div :class="whyUs ? 'whyUs-card' : 'advantages-card'">
    <div class="advantages-card-image" v-if="content.poster">
      <img :src="content.poster" alt="content-image" />
    </div>
    <h2 v-if="whyUs" class="primary-red">{{ content.title }}</h2>
    <h3 v-else>{{ content.title }}</h3>
    <span class="card-description">
      {{ content.description }}
    </span>
  </div>
</template>

<script>
export default {
  props: ["content", "whyUs"],
};
</script>

<style></style>
