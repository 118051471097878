<template>
  <div class="partners-wrapper" :class="payload.class ? payload.class : ''">
    <div class="partners container">
      <div class="partners-description">
        <h1 class="partners-header">
          {{ payload.header }}
        </h1>
        <p class="partners-description-text">
          {{ payload.description }}
        </p>
      </div>
      <div
        class="partners-custom"
        v-if="payload.button"
        v-html="payload.button"
        @click="payload.onClick"
      ></div>

      <!-- <div class="partners-images">
        <img v-for="image in payload.images" :key="image.id" :src="image.poster" alt="">
      </div> -->

      <div class="partners-images" v-if="filteredImages && filteredImages.length">
        <carousel :autoplay="5000" :wrap-around="true" snapAlign="start">
          <slide
            v-for="(block, index) in filteredImages"
            :key="index"
          >
            <div class="carousel-images-block" :class="block.length > 3 && 'col-2'">
              <div class="partners-image-wrapper" v-for="image in block" :key="image.id">
                <img :src="image.poster" alt="partners-image" />
              </div>
            </div>
          </slide>
          <template #addons>
            <Navigation />
            <Pagination />
          </template>
        </carousel>
      </div>
    </div>
  </div>
</template>

<script>
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Navigation, Pagination } from 'vue3-carousel';
// import $ from 'jquery'

export default {
  props: ["payload"],
  components: {Carousel, Slide, Navigation, Pagination},
  computed: {
    filteredImages(){
      let res = []
      let temp = []
      for (let image in this.payload.images){
        if (temp.length >= 6){
          res.push(temp)
          temp = []
        }
        temp.push(this.payload.images[image])
      }
      if (temp.length){
        res.push(temp)
      }
      return res
    }
  },
  mounted(){
    console.log('PARTNERS MOUNTED')
    for (let i in this.payload.images){
      console.log(i)
    }
  },
  updated(){
    console.log('UPDATED HOOK INVOKED!')
    // $('.gallery').slick({
    //     autoplay: true,
    //     dots: true,
    //     responsive: [{
    //       breakpoint: 500,
    //       settings: {
    //         dots: false,
    //         arrows: false,
    //         infinite: false,
    //         slidesToShow: 2,
    //         slidesToScroll: 2
    //       }
    //     }]
    //   });
  }
};
</script>

<style></style>
