<template>
  <div class="contacts-wrapper container">
    <h1>Контакты</h1>
    <div class="contacts">
      <div class="phone-and-mail">
        <div class="contacts-item phone">
          <span>Телефон:</span>
          <a href="tel:+7(707)409-22-44"><h2>{{contacts.phone}}</h2></a>
        </div>
        <div class="contacts-item phone">
          <span>E-mail:</span>
          <a :href="`mailto:${contacts.email}`"><h2>{{contacts.email}}</h2></a>
        </div>
      </div>
      <div class="schedule">
        <div class="schedule-item pn-pt">
          <p>{{contacts.monday_friday}}</p>
          <span>Понедельник - Пятница</span>
        </div>
        <div class="schedule-item sb">
          <p>{{contacts.saturday}}</p>
          <span>Суббота</span>
        </div>
        <div class="schedule-item consult-whatsapp">
          <p>{{contacts.saturday_sunday}}</p>
          <span>Суббота и Воскресенье</span>
        </div>
      </div>
      <p class="contacts-description">
        {{contacts.text}}
      </p>
      <div class="director">
        <h2>{{contacts.director}}</h2>
        <span>Генеральный Директор</span>
      </div>
      <h2>{{contacts.address}}</h2>
      <!-- 
        8 727 224 21 21,
        8 707 409 22 44,
        8 707 409 22 66
        almaty@hseipaa.kz 
      -->

      <div class="contacts-links">
        <a href="tel:8 727 224 21 21"><h2>8 727 224 21 21</h2></a>
        <a href="tel:8 707 409 22 44"><h2>8 707 409 22 44</h2></a>
        <a href="tel:8 707 409 22 66"><h2>8 707 409 22 66</h2></a>
        <a href="mailto:almaty@hseipaa.kz"><h2>almaty@hseipaa.kz</h2></a>
      </div>
    </div>
    <!-- <div class="container"> -->
    <accordion :items="accordionItems"></accordion>
    <!-- </div> -->
    <app-form></app-form>
  </div>
</template>

<script>
import Accordion from "../components/UI/Accordion.vue";
import AppForm from "../components/Form.vue";

export default {
  components: { Accordion, AppForm },
  data() {
    return {
      accordionItems: [],
      contacts: {}
    };
  },
  mounted(){
    let content = this.$store.state.content
    this.accordionItems = content.faq.items
    this.contacts = content.contact
  }
};
</script>

<style></style>
