<template>
  <div class="form bg-purple white">
    <vue-final-modal
      v-model="showModal"
      classes="modal-container-form"
      content-class="modal-content modal-content-form"
      name="modalForm"
    >
      <div class="modal-form-header" :class="submitted ? 'submitted' : ''">
        <h2>{{ submitted ? "Спасибо!" : "Заявка на курс" }}</h2>
        <img
          @click="$vfm.hide('modalForm')"
          src="../assets/img/close-button.svg"
          alt=""
        />
      </div>
      <form v-if="!submitted">
        <div
          class="float-container"
          :class="{ active: inputContainers.fullname.active }"
        >
          <label for="modalForm-fullname">Ф.И.О</label>
          <input
            v-model="inputContainers.fullname.value"
            id="modalForm-fullname"
            class="gray-outlined form-input with-label"
            type="text"
            @focus="inputContainers.fullname.active = true"
            @blur="
              inputContainers.fullname.active = inputContainers.fullname.value
                ? true
                : false
            "
          />
        </div>
        <div
          class="float-container"
          :class="{ active: inputContainers.phone.active }"
        >
          <label for="modalForm-phone">Телефон</label>
          <input
            v-model="inputContainers.phone.value"
            id="modalForm-phone"
            class="gray-outlined form-input with-label"
            type="text"
            @focus="inputContainers.phone.active = true"
            @blur="
              inputContainers.phone.active = inputContainers.phone.value
                ? true
                : false
            "
          />
        </div>
        <div
          class="float-container"
          :class="{ active: inputContainers.email.active }"
        >
          <label for="modalForm-email">E-mail</label>
          <input
            v-model="inputContainers.email.value"
            id="modalForm-email"
            class="gray-outlined form-input with-label"
            type="text"
            @focus="inputContainers.email.active = true"
            @blur="
              inputContainers.email.active = inputContainers.email.value
                ? true
                : false
            "
          />
        </div>
        <!-- <input class='gray-outlined form-input' type="text" placeholder="Телефон">
        <input class='gray-outlined form-input' type="text" placeholder="E-mail"> -->
        <div class="submit-btn-group">
          <recaptcha ref="recaptcha" @verify="executeRecaptcha"></recaptcha>
          <button @click.prevent="submitForm" class="form-input btn-red">
            Отправить
          </button>
        </div>
        <span class="error" v-if="error">{{ error }}</span>
        <!-- <button @click="executeRecaptcha">execute recaptcha</button> -->
        <!-- listen to verify event emited by the recaptcha component -->
      </form>
      <div v-else class="thanks-form">
        <svg
          width="80"
          height="80"
          viewBox="0 0 80 80"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="40"
            cy="40"
            r="36.6667"
            stroke="#00A357"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M26.6666 45.2381L38.0952 52.8571L53.3333 30"
            stroke="#00A357"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <p>
          Мы получили вашу заявку, наш менеджер свяжется с Вами в течение часа
        </p>
        <button class="form-input btn-red" @click="$vfm.hide('modalForm')">
          Отлично!
        </button>
      </div>
    </vue-final-modal>
    <h2 class="white">
      Оставьте заявку - мы обсудим ваши цели и подберем решения
    </h2>
    <div class="form-input-group">
      <input
        type="text"
        class="form-input"
        placeholder="Введите номер телефона"
      />
      <input type="text" class="form-input" placeholder="Ваше имя" />
      <input type="text" class="form-input" placeholder="Ваш e-mail" />
      <button class="form-input btn-red" @click="$vfm.show('modalForm')">
        <strong>Оставить заявку</strong>
      </button>
    </div>
  </div>
</template>

<script>
import Recaptcha from "./UI/Recaptcha.vue";
import axios from "axios";

export default {
  components: { Recaptcha },
  data() {
    return {
      showModal: false,
      submitted: false,
      inputContainers: {
        fullname: {
          value: "",
          active: false,
        },
        phone: {
          value: "",
          active: false,
        },
        email: {
          value: "",
          active: false,
        },
      },
      recaptchaChecked: false,
      error: "",
    };
  },
  methods: {
    async submitForm() {
      if (this.recaptchaChecked) {
        axios
          .post("https://on.hseipaa.kz/", {
            email: this.inputContainers.email.value,
            name: this.inputContainers.fullname.value,
            phone: this.inputContainers.phone.value,
          })
          .then((res) => {
            console.log(res);
            this.submitted = true;
          })
          .catch((err) => console.log(err));
      } else {
        this.error = "Докажите, что вы не робот";
      }
    },
    executeRecaptcha() {
      this.recaptchaChecked = true;
      this.error = "";
    },
  },
};
</script>
