<template>
  <footer class="container" id="app-footer">
    <div class="footer-logo">
      <img
        src="../assets/img/logo_label.svg"
        alt="footer-logo"
        class="footer-logo-image"
      />
    </div>
    <div class="footer-main">
      <div class="footer-links">
        <a v-for="link in orderedFooterLinks" :key="link.id" :href="link.url">{{link.title}}</a>
      </div>

      <div class="footer-app-links">
        <a
          href="https://apps.apple.com/kz/app/hseipaa/id1561909885"
          target="_blank"
          class="app-store app-link"
        >
          <img src="../assets/img/app_store.svg" alt="app-store" />
          <span class="primary-gray">Скачать в App Store</span>
        </a>
        <a
          href="https://play.google.com/store/apps/details?id=com.buginsoft.hseipaa&hl=ru&gl=US"
          target="_blank"
          class="play-market app-link"
        >
          <img src="../assets/img/play_marlet.svg" alt="play-market" />
          <span class="primary-gray">Скачать в Google Play</span>
        </a>
      </div>

      <div class="footer-contacts">
        <a :href="`tel:${$store.state.content.contact.phone}`" class="phone">
          <strong>{{$store.state.content.contact.phone}}</strong>
        </a>
        <a :href="`mailto:${$store.state.content.contact.email}`" class="email primary-gray"
          >{{$store.state.content.contact.email}}</a
        >
        <ul class="social">
          <li>
            <a target="_blank" :href="links.lelegram">
              <img src="../assets/img/icon_tg.svg" alt="icon_tg" />
            </a>
          </li>
          <li>
            <a target="_blank" :href="links.facebook">
              <img src="../assets/img/icon_fb.svg" alt="icon_tg" />
            </a>
          </li>
          <li>
            <a target="_blank" :href="links.youtube">
              <img src="../assets/img/icon_youtube.svg" alt="icon_tg" />
            </a>
          </li>
          <li>
            <a target="_blank" :href="links.instagram">
              <img src="../assets/img/icon_inst.svg" alt="icon_tg" />
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div class="copyright">
      <span class="primary-gray"
        >© 2006 - 2021 « Высшая Школа Экономики Институт профессиональных
        бухгалтеров и аудиторов »</span
      >
    </div>
  </footer>
</template>

<script>
export default {
  props: ['links', 'footer_links'],
  computed: {
    orderedFooterLinks(){
      let links = this.footer_links
      return links.sort((a, b) => a.position - b.position)
    }
  }
};
</script>

<style></style>
