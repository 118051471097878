<template>
  <div class="main-banner-wrapper" v-if="payload && slides">
    <carousel :autoplay='5000' :wrap-around="true">
      <slide v-for="slide in slides" :key="slide.id">
        <div class="main-banner" :style="`background-image: url(${slide.poster})`">
          <div class="main-banner-grid container">
            <div class="mainBanner-content">
              <h1 class="banner-label">
                {{slide.title}}
              </h1>
              <p class="banner-description">
                {{slide.description}}
              </p>
            </div>

            <div class="playVideo">
              <div class="playButton-wrapper" @click="show(slide.video)">
                <img
                  src="../assets/img/play_button.svg"
                  alt="play_button"
                  class="playButton"
                />
              </div>
              <span @click="show(slide.video)"> Смотреть ролик </span>
            </div>
          </div>
        </div>
      </slide>
      <template #addons>
        <div v-if="payload.length > 1" style="position: absolute; width: 100%; top: 50%">
          <div class="container-mainbanner">
            <Navigation />
          </div>
        </div>
        <Pagination />
      </template>
    </carousel>
    <vue-final-modal
      v-model="showVideo"
      classes="modal-container-form"
      content-class="modal-content iframe-wrapper"
      name="mainBannerModal"
    >
      <div v-html="currentVideo" style="width: 100%; height: 100%;"></div>
    </vue-final-modal>
  </div>
</template>

<script>
import { Carousel, Slide, Navigation, Pagination } from 'vue3-carousel';

export default {
  components: { Carousel, Slide, Navigation, Pagination },
  props: ['payload'],
  data() {
    return {
      showVideo: false,
      slides: {},
      loaded: false,
      currentVideo: ''
    };
  },
  watch:{
    payload(){
      if (!this.loaded){
        if (this.payload){
          this.loaded = true
        }
        this.slides = this.payload
        for (let i in this.slides){
          this.slides[i].showVideo = false
        }
      }
    }
  },
  methods: {
    show(video){
      this.showVideo = !this.showVideo
      this.currentVideo = video
    }
  }
};
</script>

<style scoped>
</style>