<template>
  <div class="steps-wrapper container" v-if="payload">
    <div class="steps-section" v-for="(step, index) in stepsComputed" :key="index">
      <h2>{{ `${step.position}. ${step.title}` }}</h2>
      <div class="step-image">
        <img :src="step.poster" alt="step-image" />
      </div>
      <div class="steps-right">
        <div v-html="step.description"></div>
        <button v-if="step.position === stepsComputed.length" class="btn-main btn-begin btn-red" @click="$vfm.show('modalForm')">Начать учиться</button>
      </div>
    </div>
  </div>
</template>

<script>
// import img1 from "../assets/img/steps_1.svg";
// import img2 from "../assets/img/steps_2.svg";
// import img3 from "../assets/img/steps_3.svg";

export default {
  props: ['payload'],
  computed: {
    stepsComputed(){
      let temp = this.payload
      return temp.sort((a, b) => a.position - b.position)
    }
  }
};
</script>

<style></style>
