<template>
  <div class="app-wrapper" v-if="$store.state.loaded">
    <header-switch :payload="$store.state.content.phone"></header-switch>
    <div id="nav">
      <main-header :payload="$store.state.content.page" :links="$store.state.content.contact"></main-header>
    </div>
    <router-view />
    <app-footer :links="$store.state.content.contact" :footer_links="$store.state.content.footer.items"></app-footer>
    <mobile-navbar></mobile-navbar>
    <scroll-top></scroll-top>
    <div class="menu" @click="showMenu = !showMenu">
      <svg
        v-if="!showMenu"
        width="58"
        height="58"
        viewBox="0 0 58 58"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="29" cy="29" r="29" fill="#EF533F" />
        <path
          d="M20.0002 22H38.0002"
          stroke="white"
          stroke-width="1.6"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M20.0002 29H38.0002"
          stroke="white"
          stroke-width="1.6"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M20.0002 36H38.0002"
          stroke="white"
          stroke-width="1.6"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>

      <svg
        v-else
        width="66"
        height="66"
        viewBox="0 0 66 66"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_d)">
          <circle cx="33" cy="33" r="29" fill="white" />
          <path
            d="M25 25L41 41"
            stroke="#6F7893"
            stroke-width="1.2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M25 41L41 25"
            stroke="#6F7893"
            stroke-width="1.2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M25 25L41 41"
            stroke="#6F7893"
            stroke-width="1.2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M25 41L41 25"
            stroke="#6F7893"
            stroke-width="1.2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </g>
        <defs>
          <filter
            id="filter0_d"
            x="0"
            y="0"
            width="66"
            height="66"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="2" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    </div>
    <vue-final-modal
      v-model="showMenu"
      name="menu"
      z-index="999"
      content-class="menuContent"
      :lock-scroll="false"
    >
      <ul class="contact-modal-actions">
        <li @click="$vfm.show('modalForm')">
          <span class="modal-actions-link white"
            >Оставить заявку на обучение</span
          >
          <img src="./assets/img/contacts-modal/button-send.svg" alt="" />
        </li>
        <li>
          <a :href="`tel:${$store.state.content.contact.phone}`" :title="$store.state.content.contact.phone">
            <span class="modal-actions-link white">Позвонить</span>
          </a>
          <img src="./assets/img/contacts-modal/button-call.svg" alt="" />
        </li>
        <li>
          <a :href="$store.state.content.contact.whatsapp" :title="$store.state.content.contact.phone">
            <span class="modal-actions-link white">Написать в WhatsApp</span>
          </a>
          <img src="./assets/img/contacts-modal/button-whatsapp.svg" alt="" />
        </li>
        <li>
          <a :href="$store.state.content.contact.telegram">
            <span class="modal-actions-link white">Написать в Telegram</span>
          </a>
          <img src="./assets/img/contacts-modal/button-tg.svg" alt="" />
        </li>
      </ul>
    </vue-final-modal>
  </div>
</template>

<script>
import MainHeader from "./components/Header.vue";
import AppFooter from "./components/Footer.vue";
import MobileNavbar from "./components/MobileNavbar.vue";
import HeaderSwitch from "./components/HeaderSwitch.vue";
import ScrollTop from "./components/UI/ScrollTopButton.vue";
import Cookies from "js-cookie";
import "normalize.css";
import {mapState} from 'vuex'

export default {
  components: { MainHeader, AppFooter, MobileNavbar, HeaderSwitch, ScrollTop },
  data() {
    return {
      showMenu: false,
      // content: {}
    };
  },
  methods: {
    TranslateInit() {
      let google = window.google;
      if (
        this.$store.state.googleTranslateConfig.langFirstVisit &&
        !Cookies.get("googtrans")
      ) {
        // Если установлен язык перевода для первого посещения и куки не назначены
        this.$store.dispatch(
          "TranslateCookieHandler",
          "/auto/" + this.$store.state.googleTranslateConfig.langFirstVisit
        );
      }
      let sample =
        Cookies.get("googtrans") != undefined &&
        Cookies.get("googtrans") != "null"
          ? Cookies.get("googtrans")
          : this.$store.state.googleTranslateConfig.lang;
      let code = sample.match(/(?!^\/)[^]*$/gm)[0];

      if (code == this.$store.state.googleTranslateConfig.lang) {
        // Если язык по умолчанию, совпадает с языком на который переводим
        // То очищаем куки
        this.$store.dispatch("TranslateCookieHandler", null);
      }

      // Инициализируем виджет с языком по умолчанию
      new google.translate.TranslateElement({
        pageLanguage: this.$store.state.googleTranslateConfig.lang,
      });
    },
    async getContents(){
      this.$store.dispatch('getContents')
    }
  },
  mounted() {
    this.$store.dispatch("initStore");
    window.TranslateInit = this.TranslateInit;
    // this.content = this.$store.state.content
  },
};
</script>

<style lang="scss">
@import "main.scss";
</style>
