import { createStore } from "vuex";
import Cookies from "js-cookie";
import axios from 'axios'

export default createStore({
  state: {
    lang: "ru",
    googleTranslateConfig: {
      lang: "ru",
    },
    content: {},
    loaded: false
  },
  mutations: {
    changeLang(state, payload) {
      state.lang = payload;
    },
    setContents(state, payload){
      state.content = payload
    },
    setLoaded(state, payload){
      state.loaded = payload
    }
  },
  actions: {
    initStore({commit, dispatch}) {
      let cookie = Cookies.get('googtrans')
      if (cookie){
        let lang = cookie.substring(cookie.length-2)
        commit('changeLang', lang)
      }
      // if (localStorage.getItem("lang") !== null) {
      //   let lang = localStorage.getItem("lang");
      // }
      dispatch('getContents')
    },
    changeLang({state, commit}, payload) {
      commit("changeLang", payload);
      Cookies.set("googtrans", `/${state.googleTranslateConfig.lang}/` + payload);
      // localStorage.setItem("lang", state.lang);
      window.location.reload();
    },
    getContents({commit}){
      axios.get('https://on.hseipaa.kz/api/custom/landing')
      .then(res => {
        commit('setContents', res.data);
        commit('setLoaded', true)
      })
    }
  },
  modules: {},
});
