<template>
  <div class="container" v-if="pages && pages[selectedSection]">
    <h1>{{pages[selectedSection].title}}</h1>
    <select name="section" id="section-select" v-model="selectedSection">
      <option v-for="key in Object.keys(pages)" :key="key" :value="key">{{pages[key].title}}</option>
    </select>
    <div class="about-wrapper">
      <div class="about-content">
        <div
          v-if="pages[selectedSection]"
          class="paragraph"
          v-html="pages[selectedSection].description"
        >
        </div>
      </div>
      <div class="about-nav">
        <ul>
          <li
            @click="selectedSection = key"
            v-for="key in Object.keys(pages)"
            :key="key"
            :class="key === selectedSection ? 'active' : ''"
          >
            <span>{{ pages[key].title }}</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="container">
    <accordion :items="accordionItems"></accordion>
    <app-form></app-form>
  </div>
</template>

<script>
import Accordion from "../components/UI/Accordion.vue";
import AppForm from "../components/Form.vue";

export default {
  components: { Accordion, AppForm },
  data() {
    return {
      selectedSection: this.$route.params.page ? this.$route.params.page : 0,
      accordionItems: [],
      pages: {}
    };
  },
  mounted(){
    let content = this.$store.state.content
    this.accordionItems = content.faq.items
    this.pages = content.page
    this.selectedSection = Object.keys(this.pages)[0]
    if (this.$route.params.page){
      this.selectedSection = this.$route.params.page
    }
  }
};
</script>

<style></style>
