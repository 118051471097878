<template>
  <div class="whyUs-wrapper container" v-if="payload">
    <div class="whyUs-text">
      <h1 class="primary-black">Почему мы?</h1>
      <p class="primary-black">
        11 лет на рынке профессионального образования. Международные стандарты
        обучения. Самое лучшее качество по приемлемым ценам.
      </p>
      <div class="whyUs-cards">
        <card
          v-for="(card, index) in payload.items"
          :key="index"
          :whyUs="true"
          :content="card"
        ></card>
      </div>
    </div>
    <div class="whyUs-image">
      <div class="image">
        <svg
          width="64"
          height="64"
          viewBox="0 0 64 64"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          @click="$vfm.show('mainBannerModal')"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M64 32C64 49.6727 49.6727 64 32 64C14.3273 64 0 49.6727 0 32C0 14.3273 14.3273 0 32 0C49.6727 0 64 14.3273 64 32Z"
            fill="#EF533F"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M24 21.3389V42.6616C24 44.8589 26.7387 46.1149 28.656 44.7949L44.168 34.1336C45.7227 33.0669 45.7227 30.9336 44.168 29.8669L28.656 19.2056C26.7387 17.8856 24 19.1416 24 21.3389Z"
            fill="white"
          />
        </svg>
      </div>
      <span class="primary-gray"
        >*Объязательно ознакомтесь с
        <span class="primary-red">Правилами обучения</span></span
      >
    </div>
  </div>
</template>

<script>
import Card from "./UI/AdvantagesCard.vue";

export default {
  props: ['payload'],
  components: { Card },
};
</script>

<style></style>
