<template>
  <div class="card">
    <div class="card-top">
      <h3 class="card-header">
        {{ course.title }}
      </h3>
      <div class="card-description">
        {{ course.short_description }}
      </div>
    </div>
    <div class="card-details">
      <div class="card-detail card-date">
        <span class="card-details-key">Дата:</span>
        <span class="card-details-value">{{ date }}</span>
      </div>
      <div class="card-detail card-place">
        <span class="card-details-key">Место:</span>
        <span class="card-details-value">{{ place }}</span>
      </div>
      <div class="card-detail card-certificate">
        <span class="card-details-key">Сертификат:</span>
        <span class="card-details-value">{{
          course.certification ? "Есть" : "Нет"
        }}</span>
      </div>
    </div>
    <div class="card-image">
      <img :src="course.poster" alt="card-image" />
    </div>
    <a
      :href="`https://on.hseipaa.kz/course/${course.id}`"
      target="_blank"
      class="card-price"
    >
      Стоимость:
      <span class="price">&nbsp;{{ course.price }} ₸</span>
    </a>
  </div>
</template>

<script>
export default {
  props: ["course"],
  data() {
    return {
      date: "Постоянно",
      place: "Алматы",
    };
  },
};
</script>

<style></style>
