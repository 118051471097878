<template>
  <div class="accordion-item">
    <div class="heading" @click="toggle()">
      <h2>{{ header }}</h2>
      <div class="accordion-image">
        <img
          src="../../assets/img/plus.svg"
          alt="plus-button"
          :style="
            show ? 'transform: rotate(45deg);' : 'transform: rotate(0deg);'
          "
        />
      </div>
    </div>
    <transition name="slide-fade">
      <p class="accordion-content" v-if="show">
        {{ content }}
      </p>
    </transition>
  </div>
</template>

<script>
export default {
  props: ["header", "content"],
  data() {
    return {
      show: false,
    };
  },
  methods: {
    toggle() {
      this.show = !this.show;
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../_vars.scss";

.accordion-item {
  display: grid;
  margin: 1rem 0;
  border-bottom: solid $lightgray 2px;
  padding: 1rem 0;

  h2 {
    margin: 0;
    font-weight: 400;
  }

  .heading {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    cursor: pointer;
  }

  .accordion-content {
    background-color: $lightgray;
    border-radius: 0.5rem;
    padding: 1rem;
  }
  .accordion-image img {
    transition: transform 0.5s;
  }
}

.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
</style>
